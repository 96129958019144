import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LogService } from '../logger/log.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  constructor(protected authenticationService: AuthenticationService, protected logService: LogService) {}

}
